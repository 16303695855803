<template>
<div class="login-wrapper">
    <div class="form-login">
        <h2>Cyruspay</h2>
        <img src="/assets/img/database-upload-icon.png" width="200px" height="200px"/>
        <h4>Server is updating</h4>
        <h6>please try again in a few minutes</h6>
    </div>
</div>
</template>

<script>
import ApiManager from "@/api/ApiManager";
import * as Session from "@/utils/SessionStore";
export default {
    name: "ServerIsUpdating",
    components: {},
    data() {
        return {
        };
    },
    created: function () {
        this.checkServer()
    },
    methods: {
        checkServer() {
            ApiManager.checkServerIsUpdating()
            .then((response) => {
                if(response.isEnable) {
                    Session.set('hasGetHandshake', false)
                    this.$router.push('/login');
                }
            }).catch(() => {
            });
        }
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
@import '/assets/css/login.css';
</style>
